import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './brokerTrend.css';

const amountToShow = 5;

class BrokerTrend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            stockTicker: '',
            error: '',
            options: {
                chart: {
                    animations: { enabled: false },
                    height: 350,
                    type: 'candlestick',
                    events: {
                        mouseMove: (event, chartContext, config) => {
                            if (config.seriesIndex !== -1 && config.dataPointIndex !== -1) {
                                const { dataPointIndex } = config;
                                this.updateTooltip({
                                    dataPointIndex,
                                    data: this.state.series[0].data[dataPointIndex],
                                    chartContext
                                });
                            }
                        },
                        mounted: (chartContext, config) => {
                            this.hideBottomBrokers(chartContext);
                        }
                    }
                },
                title: {
                    text: 'CandleStick Chart',
                    align: 'left'
                },
                stroke: {
                    width: 2
                },
                tooltip: {
                    enabled: true // hidden in css
                },
                theme: { mode: 'dark' },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: [
                    {
                        seriesName: 'Stock Data',
                        title: {
                            text: 'Stock Price'
                        }
                    },
                    {
                        opposite: true,
                        seriesName: 'Broker Data',
                        title: {
                            text: 'Broker Volume'
                        },
                        labels: {
                            formatter: function (val) {
                                if (!val) return '';
                                const valAbs = Math.abs(val);
                                if (valAbs >= 1000000000) {
                                    return (val / 1000000000).toFixed(0) + 'B';
                                } else if (valAbs >= 1000000) {
                                    return (val / 1000000).toFixed(0) + 'M';
                                } else if (valAbs >= 1000) {
                                    return (val / 1000).toFixed(0) + 'K';
                                } else {
                                    return val.toFixed(0);
                                }
                            }
                        }
                    }
                ]
            },
            series: [],
            hiddenBrokers: [] // Track hidden brokers
        };
    }

    handleStockTickerChange = (event) => {
        const value = event.target.value.toUpperCase().slice(0, 3); // Limit to 3 characters and convert to uppercase
        this.setState({ stockTicker: value });
    };

    fetchData = async (chartContext) => {
        if (!this.validateInputs()) {
            return;
        }

        const { startDate, endDate, stockTicker } = this.state;
        const tickerData = stockTicker; // Use the state variable for the stock ticker
        let hiddenSeries = [];

        if (chartContext?.data) {
            hiddenSeries = chartContext.w.globals.seriesNames.filter((_, index) => {
                return chartContext.w.globals.series[index].length === 0;
            });
            this.setState({ hiddenBrokers: hiddenSeries });
        }

        try {
            const response = await fetch('/api/stock-price-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ticker: tickerData,
                    start: startDate.toISOString(),
                    end: endDate.toISOString()
                })
            });
            if (!response.ok) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }
            const { stockData, uniqueEntities } = await response.json();

            const formattedCandlestickData = [];
            const brokerVolumeData = new Map();

            // Initialize brokerVolumeData with empty arrays for each unique entity
            uniqueEntities.forEach((entity) => {
                brokerVolumeData.set(entity, []);
            });

            Object.entries(stockData).forEach(([date, { stockChartData, brokerData }]) => {
                if (stockChartData) {
                    formattedCandlestickData.push({
                        x: new Date(date).getTime(),
                        y: [stockChartData.Open, stockChartData.High, stockChartData.Low, stockChartData.Close]
                    });
                }

                // Loop through uniqueEntities and assign values to brokerVolumeData accordingly
                uniqueEntities.forEach((entity) => {
                    const brokerEntry = brokerData.find((broker) => broker.entity === entity);
                    const previousVolume = brokerVolumeData.get(entity).slice(-1)[0]?.y || 0;
                    brokerVolumeData.get(entity).push({
                        x: new Date(date).getTime(),
                        y: brokerEntry ? previousVolume + brokerEntry.netvolume : previousVolume
                    });
                });
            });

            // Calculate the absolute value of the last entry for each broker
            const volumes = Array.from(brokerVolumeData.entries()).map(([name, data]) => ({
                name,
                lastVolume: Math.abs(data[data.length - 1]?.y || 0),
                data
            }));

            // Sort brokers by the absolute value of the last entry in descending order
            const brokers = volumes.sort((a, b) => b.lastVolume - a.lastVolume);

            const brokerSeries = brokers.map(({ name, data }) => ({
                name,
                type: 'line',
                data
            }));

            this.setState(
                {
                    series: [
                        {
                            name: 'Stock Data',
                            type: 'candlestick',
                            data: formattedCandlestickData
                        },
                        ...brokerSeries
                    ]
                },
                () => {
                    // Hide brokers after setting state
                    const chartContext = this.chartContext;
                    if (chartContext?.data) {
                        this.hideBrokers(chartContext, hiddenSeries);
                    }
                }
            );
            this.setState({ error: '' });
        } catch (error) {
            this.setState({ error: error });
            console.error('Error fetching stock price data:', error);
        }
    };

    handleStartDateChange = (date) => {
        this.setState({ startDate: date });
    };

    handleEndDateChange = (date) => {
        this.setState({ endDate: date });
    };

    updateTooltip(tooltipData) {
        const { dataPointIndex, chartContext } = tooltipData;
        const colors = chartContext.theme.colors;

        const tooltipElement = document.getElementById('custom-tooltip');
        if (tooltipElement) {
            let tooltipHTML = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    ${new Date(tooltipData.data.x).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })}
                </div>
            `;

            this.state.series.forEach((series, seriesIndex) => {
                if (chartContext.w.globals.series[seriesIndex].length === 0) {
                    // Skip hidden series
                    return;
                }

                const data = series.data[dataPointIndex];
                tooltipHTML += `
                    <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                        <span class="apexcharts-tooltip-marker" style="background-color: ${colors[seriesIndex % colors.length]}"></span>
                        <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            <div class="apexcharts-tooltip-y-group">
                                <span class="apexcharts-tooltip-text-y-label">${series.name}: </span>
                                <span class="apexcharts-tooltip-text-y-value">${Array.isArray(data.y) ? data.y.join(', ') : data.y}</span>
                            </div>
                        </div>
                    </div>
                `;
            });

            tooltipElement.innerHTML = tooltipHTML;
        }
    }

    clearTooltip() {
        const tooltipElement = document.getElementById('custom-tooltip');
        if (tooltipElement) {
            tooltipElement.innerHTML = '';
        }
    }

    hideBottomBrokers(chartContext) {
        if (chartContext?.data) {
            const { series } = this.state;
            const hiddenBrokers = [];

            series.slice(amountToShow + 1).forEach((series) => {
                hiddenBrokers.push(series.name);
            });

            this.setState({ hiddenBrokers }, () => {
                this.hideBrokers(chartContext, hiddenBrokers);
                this.showBrokers(
                    chartContext,
                    series.slice(1, amountToShow + 1).map((series) => series.name)
                );
            });
        }
    }

    hideAllBrokers(chartContext) {
        const { series } = this.state;
        this.hideBrokers(
            chartContext,
            series.slice(1).map((broker) => broker.name)
        );
    }

    hideNoBrokers(chartContext) {
        const { series } = this.state;
        this.showBrokers(
            chartContext,
            series.slice(1).map((broker) => broker.name)
        );
    }

    hideBrokers(chartContext, brokers = []) {
        const { series } = this.state;
        if (chartContext?.data) {
            brokers.forEach((broker) => {
                if (series.some((s) => s.name === broker)) {
                    chartContext.hideSeries(broker);
                }
            });
        }
    }

    showBrokers(chartContext, brokers = []) {
        const { series } = this.state;
        if (chartContext?.data) {
            brokers.forEach((broker) => {
                if (series.some((s) => s.name === broker)) {
                    chartContext.showSeries(broker);
                }
            });
        }
    }

    validateInputs = () => {
        const { startDate, endDate, stockTicker } = this.state;
        let errors = [];

        if (!stockTicker || stockTicker.length !== 3) {
            errors.push('Stock ticker must be 3 characters long.');
        }

        if (!startDate || !endDate) {
            errors.push('Both start date and end date must be selected.');
        }

        if (startDate > endDate) {
            errors.push('Start date must be before end date.');
        }

        if (errors.length > 0) {
            this.setState({ error: errors.join('<br>') });
            return false;
        }

        this.setState({ error: '' });
        return true;
    };

    render() {
        return (
            <div className="app" style={{ textAlign: 'center' }}>
                <div
                    className="row"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <div>
                        <label>Stock: </label>
                        <input
                            type="text"
                            value={this.state.stockTicker}
                            onChange={this.handleStockTickerChange}
                            maxLength={3}
                            style={{ textTransform: 'uppercase' }}
                        />
                    </div>
                    <div>
                        <label>Start Date: </label>
                        <DatePicker selected={this.state.startDate} onChange={this.handleStartDateChange} />
                    </div>
                    <div>
                        <label>End Date: </label>
                        <DatePicker selected={this.state.endDate} onChange={this.handleEndDateChange} />
                    </div>
                    <button onClick={() => this.fetchData(this.chartContext)}>Submit</button>
                </div>
                {this.state.error && (
                    <p id="error" style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: this.state.error }}></p>
                )}
                {this.state.series.length > 1 && (
                    <div>
                        <div className="mixed-chart" style={{ marginTop: '20px' }}>
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                height="500"
                                ref={(chart) => {
                                    this.chartContext = chart ? chart.chart.ctx : null;
                                }}
                            />
                        </div>
                        <div
                            className="row"
                            style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '10px' }}>
                            <button onClick={() => this.hideBottomBrokers(this.chartContext)}>
                                Show Top {amountToShow} Brokers
                            </button>
                            <button onClick={() => this.hideAllBrokers(this.chartContext)}>Hide All Brokers</button>
                            <button onClick={() => this.hideNoBrokers(this.chartContext)}>Show All Brokers</button>
                        </div>
                        <div id="custom-tooltip" className="custom-tooltip"></div>
                    </div>
                )}
            </div>
        );
    }
}

export default BrokerTrend;
