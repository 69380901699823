import React from 'react';
import './additionalInfo.css';

const ExpandedRow = ({ item, today }) => {
    const { news, trend, change, market_cap_basic, relative_volume_10d_calc, gap_percentage } = item;

    const compareValue = change ? change : gap_percentage ? gap_percentage : null;
    const trendDays = (compareValue > 0 ? trend.daysUp : trend.daysDown) + (trend.lastChecked === today ? 0 : 1);
    const trendValue = trend.lastChecked
        ? 'Trend: ' + (compareValue > 0 ? 'up ' : 'down ') + trendDays + ' day(s)'
        : 'Trend: 0 days';

    return (
        <tr className="row-expanded">
            <td colSpan={9}>
                <div className="expanded-content">
                    <div className="full-width">
                        <p>{trendValue}</p>
                    </div>
                    {market_cap_basic !== undefined && (
                        <div className="full-width">
                            <p>
                                Market Cap:{' '}
                                {market_cap_basic.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}
                            </p>
                        </div>
                    )}
                    {relative_volume_10d_calc !== undefined && (
                        <div className="full-width">
                            <p>Relative Volume (10d): {relative_volume_10d_calc.toFixed(2)}x</p>
                        </div>
                    )}
                    {news && news[today] && (
                        <div className="news-section full-width">
                            <h4>News:</h4>
                            {Object.entries(news[today])
                                .filter(([key]) => key !== 'alerted' && key !== 'summary')
                                .map(([key, newsItem]) => (
                                    <div key={newsItem.documentKey} className="news-item">
                                        <a href={newsItem.url} target="_blank" rel="noopener noreferrer">
                                            {newsItem.headline}
                                        </a>
                                    </div>
                                ))}
                            {news[today]?.summary && <div className="news-summary">{news[today].summary}</div>}
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default ExpandedRow;
