import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NavBar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem('accessToken');
        navigate('/login');
    };

    return (
        <nav>
            <ul>
                <li>
                    <Link to="/pre-market">Pre-Market</Link>
                </li>
                <li>
                    <Link to="/intra-day-small">Intra-Day below $1</Link>
                </li>
                <li>
                    <Link to="/intra-day-mid-large">Intra-Day above $1</Link>
                </li>
                <li>
                    <Link to="/broker-trend">Broker Trend</Link>
                </li>
                <li className="support-me">
                    <a href="https://buymeacoffee.com/embistadtsbot" target="_blank" rel="noopener noreferrer">
                        Support Me
                    </a>
                </li>
                <li className="logout">
                    <button onClick={handleLogout}>Logout</button>
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;
