import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import ExpandedRow from './ExpandedRow.js';
import CountdownTimer from './CountdownTimer.js';

const Table = ({
    title,
    refreshCountdown,
    columnConfig,
    fetchFunction,
    defaultSort,
    transformLogic,
    setTodayParent
}) => {
    const [data, setData] = useState({});
    const [transformedData, setTransformedData] = useState([]);
    const [today, setToday] = useState('');
    const [sortField, setSortField] = useState(defaultSort);
    const [sortDirection, setSortDirection] = useState('desc');
    const [expandedRow, setExpandedRow] = useState(null);
    const [error, setError] = useState(null);
    const dataRef = useRef(data);
    const sortFieldRef = useRef(sortField);
    const sortDirectionRef = useRef(sortDirection);

    const animationDuration = refreshCountdown;
    const fetchCountdown = refreshCountdown;

    useEffect(() => {
        dataRef.previous = dataRef.current;
        dataRef.current = data;
        sortFieldRef.current = sortField;
        sortDirectionRef.current = sortDirection;
    }, [data, sortField, sortDirection]);

    const fetchData = async () => {
        try {
            const response = await fetch(fetchFunction, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.dateToday) {
                setToday(result.dateToday);
                setTodayParent(result.dateToday);
            }
            const transformedData = transformLogic ? transformLogic(result.data) : result.data;
            if (transformedData && Array.isArray(transformedData)) {
                setData(result.data);
                setTransformedData(transformedData);
                applySort(transformedData, sortFieldRef.current, sortDirectionRef.current);
                setError(null);
            } else {
                setTransformedData([]);
                setError('Invalid data format received from server');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error instanceof TypeError && error.message === 'Failed to fetch') {
                setError('Network error: Failed to fetch data. Server might be offline.');
            } else {
                setError(error.message);
            }
        }
    };

    useEffect(() => {
        const currentData = dataRef.current;
        const previousData = dataRef.previous;
        const upCells = [];
        const downCells = [];
        Object.keys(currentData).forEach((key, index) => {
            const rowData = currentData[key];
            const previousRowData = previousData[key];
            if (previousRowData) {
                const columnsToCheck = columnConfig.filter((column) => column.animationColor);
                columnsToCheck.forEach((column) => {
                    const element = document.getElementById(column.name + rowData.name);
                    if (element) {
                        const currentValue = rowData[column.field];
                        const previousValue = previousRowData[column.field];
                        if (currentValue > previousValue) {
                            upCells.push(element);
                        } else if (currentValue < previousValue) {
                            downCells.push(element);
                        }
                    }
                });
            }
        });
        handleAnimation(upCells, 'up');
        handleAnimation(downCells, 'down');
    }, [data]);

    const handleAnimation = (elements, direction) => {
        const keyframes = [
            { backgroundColor: direction === 'up' ? '#00ff00' : '#ff0000' },
            { backgroundColor: 'transparent' }
        ];

        const options = {
            duration: animationDuration * 1000,
            fill: 'forwards'
        };

        elements.forEach((element) => element.animate(keyframes, options));
    };

    const handleSort = (field) => {
        const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(direction);
        applySort(transformedData, field, direction);
    };

    const applySort = (transformedData, field, direction) => {
        setTransformedData(
            [...transformedData].sort((a, b) => {
                const aValue = a[field];
                const bValue = b[field];
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }
                return direction === 'asc' ? aValue - bValue : bValue - aValue;
            })
        );
    };

    const handleRowClick = (identifier) => {
        setExpandedRow(expandedRow === identifier ? null : identifier);
    };

    const formatValue = (value, digits, format) => {
        if (isNaN(value) || value === undefined || value === null) {
            return '-';
        }
        const number = parseFloat(value);
        switch (format) {
            case 'currency':
                return number.toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: digits,
                    maximumFractionDigits: digits
                });
            case 'percent':
                return `${number.toFixed(digits)}%`;
            default:
                return number.toLocaleString('en-AU', {
                    minimumFractionDigits: digits,
                    maximumFractionDigits: digits
                });
        }
    };

    return (
        <div id="content">
            <div id="title">{title}</div>
            <div className="header-container">
                <CountdownTimer initialCount={fetchCountdown} onTimeout={fetchData} />
            </div>
            {error && <div className="error-message">Error: {error}</div>}
            <table>
                <thead>
                    <tr>
                        {columnConfig.map((column) => (
                            <th
                                key={column.field || column.name}
                                className={[column.sortable ? 'sortable' : '', column.class].join(' ')}
                                onClick={column.sortable ? () => handleSort(column.field || column.name) : undefined}>
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {transformedData.map((item, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                {columnConfig.map((column) => {
                                    let value = item[column.field || column.name];
                                    if (column.calcValue) {
                                        value = column.calcValue(item);
                                    }
                                    if (column.conditionalColor) {
                                        const comparitor = column.comparitor ? column.comparitor(item) : 0;
                                        value = (
                                            <span
                                                style={{
                                                    color:
                                                        value < comparitor
                                                            ? column.conditionalColor.negativeColor
                                                            : value > comparitor
                                                              ? column.conditionalColor.positiveColor
                                                              : ''
                                                }}>
                                                {formatValue(value, column.digits, column.format)}
                                            </span>
                                        );
                                    } else if (column.format) {
                                        value = formatValue(value, column.digits, column.format);
                                    }

                                    return (
                                        <td
                                            id={column.name + item.name}
                                            key={column.name}
                                            className={column.class}
                                            onClick={
                                                column.field === 'name' ? () => handleRowClick(item.name) : undefined
                                            }>
                                            {column.field === 'name' && (
                                                <span className="expand-icon">
                                                    {expandedRow === item.name ? '▼' : '▶'}
                                                </span>
                                            )}
                                            <span className="content">{value}</span>
                                        </td>
                                    );
                                })}
                            </tr>
                            {expandedRow === item.name && <ExpandedRow item={item} today={today} />}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
