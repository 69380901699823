import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialCount, onTimeout }) => {
    const [countdown, setCountdown] = useState(initialCount);

    useEffect(() => {
        onTimeout();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countdown <= 1) {
                clearInterval(interval);
                setCountdown(initialCount); // Reset the countdown (optional)
                if (onTimeout) {
                    onTimeout(); // Callback for when the countdown ends
                }
            } else {
                setCountdown(countdown - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countdown]);

    return <div id="refresh-timer">Refresh: {countdown}s</div>;
};

export default CountdownTimer;
