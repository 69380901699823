import React, { useState } from 'react';
import './styles.css';
import Table from './Table.js';
const title = 'Top Gap Ups';

const refreshCountdown = 15;
const fetchFunction = '/predata';
const defaultSort = 'gap_percentage';

const PreMarketTable = () => {
    const [today, setToday] = useState('');

    const transformLogic = (data) => {
        return Object.values(data).filter((item) => item.gap_percentage > 0);
    };

    const scannerColumnConfig = [
        {
            name: 'Name',
            field: 'name',
            sortable: true,
            calcValue: (item) => {
                const hasOtherNews =
                    item.news &&
                    item.news[today] &&
                    Object.keys(item.news[today]).filter((key) => key !== 'summary' && key !== 'alerted').length > 0;
                return (hasOtherNews ? '📄 ' : '') + item.name;
            },
            class: 'name-column'
        },
        { name: 'Gap %', field: 'gap_percentage', format: 'percent', digits: 1, sortable: true },
        {
            name: 'Match',
            field: 'match_price',
            format: 'currency',
            digits: 3,
            sortable: true,
            animationColor: true
        },
        { name: 'Close', field: 'p_close', format: 'currency', digits: 3 },
        { name: 'Match Volume', field: 'match_vol', sortable: true },
        {
            name: 'Match Value',
            calcValue: (item) => item.match_vol * item.match_price,
            format: 'currency',
            digits: 0
        },
        { name: 'Buy Surplus Vol', field: 'buy_surplus' }
    ];

    return (
        <div>
            <Table
                title={title}
                refreshCountdown={refreshCountdown}
                columnConfig={scannerColumnConfig}
                fetchFunction={fetchFunction}
                defaultSort={defaultSort}
                transformLogic={transformLogic}
                setTodayParent={setToday}
            />
        </div>
    );
};

export default PreMarketTable;
