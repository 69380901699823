import React, { useState } from 'react';

const Login = () => {
    const [identifier, setIdentifier] = useState('');
    const [error, setError] = useState('');

    const supportLink = 'https://buymeacoffee.com/embistadtsbot';
    const insufficientAccessMessage = `Sorry, this feature is not available to everyone. 
        Please visit <a href="${supportLink}" target="_blank">my support page</a> to join the project and gain access. 
        Let me know that you signed up so I can authenticate you.`;
    const userNotFoundMessage = `User not found, please try again or visit <a href="${supportLink}" target="_blank">my support page</a> to join the project and gain access. 
        Let me know that you signed up so I can authenticate you.`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ identifier })
            });
            const result = await response.json();
            if (result.authenticated) {
                if (result.accessLevel > 0) {
                    sessionStorage.setItem('accessToken', result.accessToken);
                    window.location.href = '/pre-market';
                } else {
                    setError(insufficientAccessMessage);
                }
            } else {
                setError(userNotFoundMessage);
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div id="login">
            <h1>Login</h1>
            <form id="login-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    id="identifier"
                    placeholder="Enter your identifier"
                    required
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                />
                <p>Your identifier can be:</p>
                <ul>
                    <li>The email address you used for the subscription</li>
                    <li>Your username on Telegram/Discord</li>
                    <li>Your chat ID from Telegram/Discord</li>
                </ul>
                <button type="submit">Submit</button>
            </form>
            {error && <p id="error" style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: error }}></p>}
        </div>
    );
};

export default Login;
